<script>
    import ResourcePage from "./ResourcePage.vue";
    import filter from "lodash/filter";
    import find from "lodash/find";
    import identity from "lodash/identity";
    import map from "lodash/map";
    import pickBy from "lodash/pickBy";

    const filterDockOptions = [
        {
            value: "undock",
            label: "Undock into Dropdown",
            icon: "mdi-dock-window",
        },
        {
            value: "top",
            label: "Dock to Top",
            icon: "mdi-dock-top",
        },
        {
            value: "left",
            label: "Dock to Left",
            icon: "mdi-dock-left",
        },
    ];

    export default {
        extends: ResourcePage,

        data: () => ({
            pollingListener: null,
            currentlyPolling: false,
            perPage: 25,
            filterHasLoaded: false,
            filterIsActive: false,
            filterDockOptions,
            selectedFilterDockOption: filterDockOptions[0],
        }),

        beforeUnmount() {
            this.stopPolling();
        },

        encodedFilters(value) {
            Nova.$emit("filter-changed", [value]);
        },

        methods: {
            initializePolling() {
                this.currentlyPolling =
                    this.currentlyPolling || this.resourceResponse.polling;

                if (this.currentlyPolling && this.pollingListener === null) {
                    return this.startPolling();
                }
            },

            togglePolling() {
                if (this.currentlyPolling) {
                    this.stopPolling();
                } else {
                    this.startPolling();
                }
            },

            stopPolling() {
                if (this.pollingListener) {
                    clearInterval(this.pollingListener);
                    this.pollingListener = null;
                }

                this.currentlyPolling = false;
            },

            startPolling() {
                this.pollingListener = setInterval(() => {
                    const selectedResources = this.selectedResources ?? [];

                    if (
                        document.hasFocus() &&
                        document.querySelectorAll("[data-modal-open]").length <
                            1 &&
                        selectedResources.length < 1
                    ) {
                        this.pollingRatio = 0;
                        this.getResources();
                    }
                }, this.pollingInterval);

                this.currentlyPolling = true;
            },

            restartPolling() {
                if (this.currentlyPolling === true) {
                    this.stopPolling();
                    this.startPolling();
                }
            },

            openDeleteModal() {
                this.deleteModalOpen = true;
            },

            deleteResources(resources, callback = null) {
                if (this.viaManyToMany) {
                    return this.detachResources(resources);
                }

                return Nova.request({
                    url: "/nova-api/" + this.resourceName,
                    method: "delete",
                    params: {
                        ...this.deletableQueryString,
                        ...{ resources: mapResources(resources) },
                    },
                })
                    .then(
                        callback
                            ? callback
                            : () => {
                                  this.getResources();
                              },
                    )
                    .then(() => {
                        Nova.$emit("resources-deleted");
                    })
                    .finally(() => {
                        this.deleteModalOpen = false;
                    });
            },

            deleteSelectedResources() {
                this.deleteResources(this.selectedResources);
            },

            deleteAllMatchingResources() {
                if (this.viaManyToMany) {
                    return this.detachAllMatchingResources();
                }

                return Nova.request({
                    url: this.deleteAllMatchingResourcesEndpoint,
                    method: "delete",
                    params: {
                        ...this.deletableQueryString,
                        ...{ resources: "all" },
                    },
                })
                    .then(() => {
                        this.getResources();
                    })
                    .then(() => {
                        Nova.$emit("resources-deleted");
                    })
                    .finally(() => {
                        this.deleteModalOpen = false;
                    });
            },

            detachResources(resources) {
                return Nova.request({
                    url: "/nova-api/" + this.resourceName + "/detach",
                    method: "delete",
                    params: {
                        ...this.deletableQueryString,
                        ...{ resources: mapResources(resources) },
                        ...{ pivots: mapPivots(resources) },
                    },
                })
                    .then(() => {
                        this.getResources();
                    })
                    .then(() => {
                        Nova.$emit("resources-detached");
                    })
                    .finally(() => {
                        this.deleteModalOpen = false;
                    });
            },

            detachAllMatchingResources() {
                return Nova.request({
                    url: "/nova-api/" + this.resourceName + "/detach",
                    method: "delete",
                    params: {
                        ...this.deletableQueryString,
                        ...{ resources: "all" },
                    },
                })
                    .then(() => {
                        this.getResources();
                    })
                    .then(() => {
                        Nova.$emit("resources-detached");
                    })
                    .finally(() => {
                        this.deleteModalOpen = false;
                    });
            },

            forceDeleteResources(resources, callback = null) {
                return Nova.request({
                    url: "/nova-api/" + this.resourceName + "/force",
                    method: "delete",
                    params: {
                        ...this.deletableQueryString,
                        ...{ resources: mapResources(resources) },
                    },
                })
                    .then(
                        callback
                            ? callback
                            : () => {
                                  this.getResources();
                              },
                    )
                    .then(() => {
                        Nova.$emit("resources-deleted");
                    })
                    .finally(() => {
                        this.deleteModalOpen = false;
                    });
            },

            forceDeleteSelectedResources() {
                this.forceDeleteResources(this.selectedResources);
            },

            forceDeleteAllMatchingResources() {
                return Nova.request({
                    url: this.forceDeleteSelectedResourcesEndpoint,
                    method: "delete",
                    params: {
                        ...this.deletableQueryString,
                        ...{ resources: "all" },
                    },
                })
                    .then(() => {
                        this.getResources();
                    })
                    .then(() => {
                        Nova.$emit("resources-deleted");
                    })
                    .finally(() => {
                        this.deleteModalOpen = false;
                    });
            },

            restoreResources(resources, callback = null) {
                return Nova.request({
                    url: "/nova-api/" + this.resourceName + "/restore",
                    method: "put",
                    params: {
                        ...this.deletableQueryString,
                        ...{ resources: mapResources(resources) },
                    },
                })
                    .then(
                        callback
                            ? callback
                            : () => {
                                  this.getResources();
                              },
                    )
                    .then(() => {
                        Nova.$emit("resources-restored");
                    })
                    .finally(() => {
                        this.restoreModalOpen = false;
                    });
            },

            restoreSelectedResources() {
                this.restoreResources(this.selectedResources);
            },

            restoreAllMatchingResources() {
                return Nova.request({
                    url: this.restoreAllMatchingResourcesEndpoint,
                    method: "put",
                    params: {
                        ...this.deletableQueryString,
                        ...{ resources: "all" },
                    },
                })
                    .then(() => {
                        this.getResources();
                    })
                    .then(() => {
                        Nova.$emit("resources-restored");
                    })
                    .finally(() => {
                        this.restoreModalOpen = false;
                    });
            },

            selectPreviousPage() {
                this.updateQueryString({
                    [this.pageParameter]: this.currentPage - 1,
                });
            },

            selectNextPage() {
                this.updateQueryString({
                    [this.pageParameter]: this.currentPage + 1,
                });
            },

            initializePerPageFromQueryString() {
                this.perPage = this.currentPerPage;
            },

            perPageChanged() {
                this.updateQueryString({
                    [this.perPageParameter]: this.perPage,
                });
            },

            async clearSelectedFilters(lens) {
                if (lens) {
                    await this.$store.dispatch(
                        `${this.resourceName}/resetFilterState`,
                        {
                            resourceName: this.resourceName,
                            lens,
                        },
                    );
                } else {
                    await this.$store.dispatch(
                        `${this.resourceName}/resetFilterState`,
                        {
                            resourceName: this.resourceName,
                        },
                    );
                }

                this.updateQueryString({
                    [this.pageParameter]: 1,
                    [this.filterParameter]: "",
                });

                Nova.$emit("filter-reset");
            },

            filterChanged() {
                let filtersAreApplied =
                    this.$store.getters[
                        `${this.resourceName}/filtersAreApplied`
                    ];

                if (filtersAreApplied || this.filterIsActive) {
                    this.filterIsActive = true;
                    this.updateQueryString({
                        [this.pageParameter]: 1,
                        [this.filterParameter]: this.encodedFilters,
                    });
                }
            },

            async initializeFilters(lens) {
                if (this.filterHasLoaded === true) {
                    return;
                }

                // Clear out the filters from the store first
                this.$store.commit(`${this.resourceName}/clearFilters`);

                await this.$store.dispatch(
                    `${this.resourceName}/fetchFilters`,
                    pickBy(
                        {
                            resourceName: this.resourceName,
                            viaResource: this.viaResource,
                            viaResourceId: this.viaResourceId,
                            viaRelationship: this.viaRelationship,
                            relationshipType: this.relationshipType,
                            lens,
                        },
                        identity,
                    ),
                );

                await this.initializeState(lens);

                this.filterHasLoaded = true;
            },

            async initializeState(lens) {
                this.initialEncodedFilters
                    ? await this.$store.dispatch(
                          `${this.resourceName}/initializeCurrentFilterValuesFromQueryString`,
                          this.initialEncodedFilters,
                      )
                    : await this.$store.dispatch(
                          `${this.resourceName}/resetFilterState`,
                          {
                              resourceName: this.resourceName,
                              lens,
                          },
                      );
            },

            filterDockChanged(option) {
                this.selectedFilterDockOption = option;
            },
        },

        computed: {
            initiallyPolling() {
                return this.resourceResponse.polling;
            },

            pollingInterval() {
                return this.resourceResponse.pollingInterval;
            },

            pollingRatioInterval() {
                return this.resourceResponse.pollingRatioInterval || 100;
            },

            shouldShowPollingToggle() {
                if (!this.resourceResponse) {
                    return false;
                }

                return this.resourceResponse.showPollingToggle || false;
            },

            deleteAllMatchingResourcesEndpoint() {
                if (this.lens) {
                    return (
                        "/nova-api/" + this.resourceName + "/lens/" + this.lens
                    );
                }

                return "/nova-api/" + this.resourceName;
            },

            forceDeleteSelectedResourcesEndpoint() {
                if (this.lens) {
                    return (
                        "/nova-api/" +
                        this.resourceName +
                        "/lens/" +
                        this.lens +
                        "/force"
                    );
                }

                return "/nova-api/" + this.resourceName + "/force";
            },

            restoreAllMatchingResourcesEndpoint() {
                if (this.lens) {
                    return (
                        "/nova-api/" +
                        this.resourceName +
                        "/lens/" +
                        this.lens +
                        "/restore"
                    );
                }

                return "/nova-api/" + this.resourceName + "/restore";
            },

            deletableQueryString() {
                return {
                    search: this.currentSearch,
                    filters: this.encodedFilters,
                    trashed: this.currentTrashed,
                    viaResource: this.viaResource,
                    viaResourceId: this.viaResourceId,
                    viaRelationship: this.viaRelationship,
                };
            },

            currentPage() {
                return parseInt(
                    this.queryStringParams[this.pageParameter] || 1,
                );
            },

            currentPerPage() {
                return this.queryStringParams[this.perPageParameter] || 25;
            },

            filterParameter() {
                return this.resourceName + "_filter";
            },

            encodedFilters() {
                return this.$store.getters[
                    `${this.resourceName}/currentEncodedFilters`
                ];
            },

            filterDockTarget() {
                return `[data-resource=${this.resourceName}] [data-dock=${this.selectedFilterDockOption.value}]`;
            },

            filterDockTop() {
                return this.selectedFilterDockOption.value === "top";
            },

            filterDockLeft() {
                return this.selectedFilterDockOption.value === "left";
            },

            defaultDockOption() {
                // prettier-ignore
                const value = this.viaResource
                    ? "undock"
                    : (this.resourceInformation.defaultFilterDockStyle || "undock");

                return find(filterDockOptions, { value });
            },
        },
    };

    function mapResources(resources) {
        return map(resources, (resource) => resource.id.value);
    }

    function mapPivots(resources) {
        return filter(map(resources, (resource) => resource.id.pivotValue));
    }
</script>
