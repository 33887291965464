import PrimeVue from "primevue/config";
import Tooltip from "primevue/tooltip";
import find from "lodash/find";
import resourceStore from "./stores/resources";
import { InertiaApp } from "./inertia";
import { Localization, RegisterComponents } from "./plugins";
import { createNovaStore } from "./stores";

export class Nova extends InertiaApp {
    constructor(config) {
        super(config);

        this.bootingCallbacks = [];

        this.registerNovaPages();
    }

    registerNovaPages() {
        const files = import.meta.glob("./pages/*.vue");

        Object.entries(files).forEach(([path, resolver]) => {
            const pageName = path.split("/").pop().split(".")[0];
            this.page("Nova." + pageName, resolver);
        });
    }

    booting(callback) {
        this.bootingCallbacks.push(callback);
    }

    boot() {
        this.store = createNovaStore();

        this.bootingCallbacks.forEach((callback) =>
            callback(this.app, this.store),
        );

        this.bootingCallbacks = [];
    }

    booted(callback) {
        callback(this.app);
    }

    vueSetup(theme) {
        super.vueSetup(theme);

        this.app
            .use(RegisterComponents, { theme })
            .use(Localization)
            .use(PrimeVue, this.primeVueConfig())
            .directive("tooltip", Tooltip);
    }

    primeVueConfig() {
        return {
            unstyled: true,
        };
    }

    liftOff() {
        this.boot();

        this.registerStoreModules();

        document.addEventListener("inertia:before", () => {
            (async () => {
                await this.store.dispatch("assignPropsFromInertia");
            })();
        });

        document.addEventListener("inertia:navigate", () => {
            (async () => {
                await this.store.dispatch("assignPropsFromInertia");
            })();
        });

        super.liftOff();
    }

    registerStoreModules() {
        this.app.use(this.store);

        this.config("resources").forEach((resource) => {
            this.store.registerModule(resource.uriKey, resourceStore);
        });
    }

    missingResource(uriKey) {
        return this.resourceInformation(uriKey) === undefined;
    }

    resourceInformation(uriKey) {
        return find(this.config("resources"), (resource) => {
            return resource.uriKey === uriKey;
        });
    }
}
