<template>
    <div class="relative">
        <div
            ref="trigger"
            class="flex items-center"
            @mouseenter="handleMouseEnter"
            @click="handleTriggerClick"
        >
            <slot
                name="trigger"
                :popper="this"
            />
        </div>
        <teleport to="body">
            <div
                v-if="show"
                ref="content"
                class="z-50"
                @click="handleTriggerClick"
            >
                <slot
                    name="content"
                    :popper="this"
                />
            </div>
        </teleport>
    </div>
</template>

<script>
    import { createPopper } from "@popperjs/core";

    export default {
        emits: ["open", "close"],

        props: {
            trigger: {
                type: String,
                default: "manual",
                validator: (v) => ["manual", "click", "hover"].includes(v),
            },

            placement: {
                type: String,
                default: "bottom-start",
                validator: (v) =>
                    [
                        "auto-start",
                        "auto-end",
                        "top-start",
                        "top-end",
                        "bottom-start",
                        "bottom-end",
                        "right-start",
                        "right-end",
                        "left-start",
                        "left-end",
                    ].includes(v),
            },
        },

        data: () => ({
            show: false,
            popper: null,
            frozen: false,
        }),

        watch: {
            show(show) {
                if (show) {
                    this.createPopper();
                    document.addEventListener(
                        "mousemove",
                        this.handleMouseMove,
                    );
                } else {
                    this.destroyPopper();
                    document.removeEventListener(
                        "mousemove",
                        this.handleMouseMove,
                    );
                }
            },
        },

        mounted() {
            document.addEventListener("keydown", this.handleEscape);
            document.addEventListener("mousedown", this.handleClickOutside);
        },

        beforeUnmount() {
            document.removeEventListener("keydown", this.handleEscape);
            document.removeEventListener("mousedown", this.handleClickOutside);
        },

        methods: {
            createPopper() {
                Nova.$emit("disable-focus-trap");

                this.$nextTick(() => {
                    this.popper = createPopper(
                        this.$refs.trigger,
                        this.$refs.content,
                        {
                            placement: this.placement,
                        },
                    );
                });
            },

            destroyPopper() {
                if (this.popper) {
                    this.popper.destroy();
                }

                Nova.$emit("enable-focus-trap");
            },

            handleEscape(e) {
                if (this.frozen) {
                    return;
                }

                // 'tab' or 'escape'
                if (this.show && (e.keyCode == 9 || e.keyCode == 27)) {
                    setTimeout(() => this.close(), 50);
                }
            },

            handleClickOutside(e) {
                if (this.frozen) {
                    return;
                }

                if (!this.show) {
                    return;
                }

                if (this.$el.contains(e.target)) {
                    return;
                }

                if (this.$refs.content?.contains(e.target)) {
                    return;
                }

                this.close();
            },

            handleMouseEnter(e) {
                if (this.trigger !== "hover") {
                    return;
                }

                if (this.show) {
                    return;
                }

                this.open();
            },

            handleMouseMove(e) {
                if (this.trigger !== "hover") {
                    return;
                }

                if (!this.show) {
                    return;
                }

                if (this.$refs.trigger.contains(e.target)) {
                    return;
                }

                if (this.$refs.content?.contains(e.target)) {
                    return;
                }

                this.close();
            },

            handleTriggerClick(e) {
                if (this.trigger !== "click") {
                    return;
                }

                this.toggle();
            },

            open() {
                this.show = true;
                this.$emit("open");
            },

            close() {
                this.show = false;
                this.$emit("close");
            },

            toggle() {
                if (this.show) {
                    this.close();
                } else {
                    this.open();
                }
            },

            update() {
                this.popper.update();
            },

            freeze() {
                this.frozen = true;
            },

            unfreeze() {
                this.frozen = false;
            },
        },
    };
</script>
