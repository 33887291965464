<template>
    <div class="flex gap-2">
        <popper>
            <template #trigger="{ popper }">
                <v-button
                    :variant="filtersAreApplied ? 'solid' : 'outline'"
                    :severity="filtersAreApplied ? 'info' : 'light'"
                    dusk="filter-selector-button"
                    icon="mdi-filter"
                    trailing-icon="mdi-chevron-down"
                    padding="tight"
                    :label="activeFilterCount > 0 ? activeFilterCount : ''"
                    :aria-label="__('Filter Dropdown')"
                    @click="popper.toggle()"
                />
            </template>
            <template #content="{ popper }">
                <dropdown-menu
                    width="300"
                    dusk="filter-menu"
                >
                    <scroll-wrap
                        :height="350"
                        class="bg-white dark:bg-gray-900"
                    >
                        <div>
                            <div
                                v-if="filters.length > 0"
                                class="bg-gray-800"
                            >
                                <button-group class="grid grid-cols-3">
                                    <v-button
                                        v-for="option in filterDockOptions"
                                        :key="option.value"
                                        variant="ghost"
                                        severity="light"
                                        padding="tight"
                                        size="lg"
                                        :icon="option.icon"
                                        :class="{
                                            'bg-gray-700':
                                                selectedFilterDockOption.value ===
                                                option.value,
                                        }"
                                        :title="__(option.label)"
                                        @click="
                                            $emit('dock-changed', option);
                                            popper.update();
                                        "
                                    />
                                </button-group>
                            </div>
                            <v-button
                                v-if="filtersAreApplied"
                                severity="muted"
                                size="sm"
                                class="border-0 w-full rounded-none uppercase"
                                @click="handleClearSelectedFiltersClick"
                                :label="__('Reset Filters')"
                            />

                            <field-grid
                                data-dock="undock"
                                class="p-4"
                                stacked
                                full-width
                            >
                                <!-- Custom Filters -->
                                <template v-if="dockSelf">
                                    <component
                                        v-for="(filter, index) in filters"
                                        :key="`${filter.class}-${index}`"
                                        :is="filter.component"
                                        :filter-key="filter.class"
                                        :lens="lens"
                                        :resource-name="resourceName"
                                        @change="handleFilterChanged"
                                        @open="popper.freeze()"
                                        @close="popper.unfreeze()"
                                    />
                                </template>

                                <!-- Soft Deletes -->
                                <filter-container
                                    v-if="softDeletes"
                                    dusk="filter-soft-deletes"
                                >
                                    <span>{{ __("Trashed") }}</span>

                                    <template #filter>
                                        <select-control
                                            v-model:selected="trashedValue"
                                            :options="[
                                                {
                                                    value: '',
                                                    label: __('&mdash;'),
                                                },
                                                {
                                                    value: 'with',
                                                    label: __('With Trashed'),
                                                },
                                                {
                                                    value: 'only',
                                                    label: __('Only Trashed'),
                                                },
                                            ]"
                                            dusk="trashed-select"
                                            @change="trashedValue = $event"
                                        />
                                    </template>
                                </filter-container>

                                <!-- Per Page -->
                                <filter-container
                                    v-if="!viaResource"
                                    dusk="filter-per-page"
                                >
                                    <span>{{ __("Per Page") }}</span>

                                    <template #filter>
                                        <select-control
                                            v-model:selected="perPageValue"
                                            :options="perPageOptionsForFilter"
                                            dusk="per-page-select"
                                            @change="perPageValue = $event"
                                        />
                                    </template>
                                </filter-container>
                            </field-grid>
                        </div>
                    </scroll-wrap>
                </dropdown-menu>
            </template>
        </popper>

        <!-- Externally Docked Filters -->
        <teleport
            v-if="!dockSelf"
            :to="filterDockTarget"
        >
            <component
                v-for="(filter, index) in filters"
                :key="`${filter.class}-${index}`"
                :is="filter.component"
                :filter-key="filter.class"
                :lens="lens"
                :resource-name="resourceName"
                @change="handleFilterChanged"
            />
        </teleport>
    </div>
</template>

<script>
    import map from "lodash/map";
    import { computed } from "vue";
    import Popper from "@nova/components/Popper.vue";
    import VButton from "@nova/components/Buttons/VButton.vue";
    import DropdownMenu from "@nova/components/Dropdowns/DropdownMenu.vue";
    import ScrollWrap from "@nova/components/ScrollWrap.vue";
    import ButtonGroup from "@nova/components/Buttons/ButtonGroup.vue";
    import FieldGrid from "@nova/components/FieldGrid.vue";
    import FilterContainer from "@nova/components/Filters/FilterContainer.vue";
    import SelectControl from "@nova/components/Inputs/SelectControl.vue";

    export default {
        components: {
            Popper,
            VButton,
            DropdownMenu,
            ScrollWrap,
            ButtonGroup,
            FieldGrid,
            FilterContainer,
            SelectControl,
        },

        emits: [
            "filter-changed",
            "clear-selected-filters",
            "trashed-changed",
            "per-page-changed",
            "dock-changed",
        ],

        props: {
            activeFilterCount: Number,
            filters: Array,
            filtersAreApplied: Boolean,
            lens: { type: String, default: "" },
            perPage: [String, Number],
            perPageOptions: Array,
            softDeletes: Boolean,
            trashed: {
                type: String,
                validator: (v) => ["", "with", "only"].includes(v),
            },
        },

        provide() {
            return {
                stacked: true,
                defaultWidth: computed(() => (this.dockTop ? "1/6" : "full")),
            };
        },

        inject: [
            "resourceName",
            "viaResource",
            "filterDockTarget",
            "filterDockOptions",
            "selectedFilterDockOption",
        ],

        methods: {
            handleFilterChanged(v) {
                if (v) {
                    const { filterClass, value } = v;

                    if (filterClass) {
                        this.$store.commit(
                            `${this.resourceName}/updateFilterState`,
                            {
                                filterClass,
                                value,
                            },
                        );
                    }
                }

                this.$emit("filter-changed");
            },

            handleClearSelectedFiltersClick() {
                Nova.$emit("clear-filter-values");

                setTimeout(() => {
                    this.$emit("clear-selected-filters");
                }, 500);
            },
        },

        computed: {
            trashedValue: {
                set(event) {
                    let value = event?.target?.value || event;

                    this.$emit("trashed-changed", value);
                },
                get() {
                    return this.trashed;
                },
            },

            perPageValue: {
                set(event) {
                    let value = event?.target?.value || event;

                    this.$emit("per-page-changed", value);
                },
                get() {
                    return this.perPage;
                },
            },

            perPageOptionsForFilter() {
                return map(this.perPageOptions, (option) => {
                    return { value: option, label: option };
                });
            },

            dockSelf() {
                return this.selectedFilterDockOption.value === "undock";
            },

            dockTop() {
                return this.selectedFilterDockOption.value === "top";
            },
        },
    };
</script>
