<template>
    <toggle-switch
        v-bind="$attrs"
        v-model="value"
        :pt="{
            root: {
                class: {
                    'w-10 h-6 inline-block relative': true,
                    'opacity-50': disabled,
                },
            },
            input: {
                class: {
                    'peer absolute inset-0 w-full h-full z-[1] opacity-0 appearance-none': true,
                    'cursor-not-allowed': disabled,
                    'cursor-pointer': !disabled,
                },
            },
            slider: {
                class: {
                    'border-none absolute rounded-full inset-0 bg-gray-300 transition-colors': true,
                    'peer-hover:bg-gray-400 peer-checked:hover:bg-green-600':
                        !disabled,
                    'peer-checked:bg-green-500': true,
                    'before:content-[\'\'] before:block before:w-4 before:h-4 before:bg-white before:rounded-full': true,
                    'before:absolute before:left-1 before:top-1/2 before:-mt-2': true,
                    'before:peer-checked:translate-x-4': true,
                    'before:duration-200': true,
                    'bg-red-500 peer-checked:bg-red-500': error,
                },
            },
        }"
    />
</template>

<style>
    .peer:checked:hover ~ .peer-checked\:hover\:bg-green-600 {
        --tw-bg-opacity: 1;
        background-color: rgb(22 163 74 / var(--tw-bg-opacity));
    }
</style>

<script>
    import ToggleSwitch from "primevue/toggleswitch";

    export default {
        components: {
            ToggleSwitch,
        },
        props: ["modelValue", "error"],
        emits: ["update:modelValue"],
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
            disabled() {
                return (
                    this.$attrs.disabled !== undefined &&
                    this.$attrs.disabled !== false
                );
            },
        },
    };
</script>
